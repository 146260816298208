<template>
  <div id="test">
    TABLAS
    
    <template>
      <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      hide-default-footer
      > 
  </v-data-table>
  <!-- hide-default-header -->
  <!-- items-per-page="5" -->
  <!-- density="compact" -->
  </template>

    <h4></h4>
    <!-- <Test></Test> -->
    <hr>
    <!-- <List></List> -->
    <v-btn
                  @click="getCTConnect"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >                
                  <v-icon>file_present</v-icon>
                  CTConnect
    </v-btn>

                <v-btn
                  @click="getMLUser"
                  color="yellow"
                  v-bind="attrs"
                  v-on="on"
                >                
                  <v-icon>visibility</v-icon>
                  Meli User
                </v-btn>
                <v-btn
                  @click="getMLAdd"
                  color="yellow"
                  v-bind="attrs"
                  v-on="on"
                >                
                  <v-icon>visibility</v-icon>
                  Meli Add
                </v-btn>

                <v-btn
                  @click="getSWAdd"
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                >                
                  <v-icon>visibility</v-icon>
                  SW Token
                </v-btn>

                <v-btn
                  @click="getTest"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                >                
                  <v-icon>visibility</v-icon>
                  Categories Syscom
                </v-btn>

                

  </div>  
</template>

<script>
  import{ mapMutations, mapState } from "vuex";
  // import{ mapState } from "vuex";
  //import Test from "@/components/Test.vue";
  // import List from "@/components/Lista.vue";
  import baseApi from '@/services/baseApi';  
  
  export default {
    name: 'test',  
    computed:{      
        ...mapState(['currentUser'])    
    },
    components: {            
        //Test,
        // List
    },  
    methods:{    
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]), 
      async getTest(){
        try{                   
          this.showLoading({titulo:"Accediendo la información a API",color:"blue"})   
          // await baseApi().get(`/ctconnect/product/1`).then((response)=>{        
          // await baseApi().get(`/syscom/products`).then((response)=>{        
          await baseApi().get(`/syscom/products/`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      
      async getSWAdd(){
        try{                   
          // await baseApi().get(`/ctconnect/product/1`).then((response)=>{        
          await baseApi().get(`/swsapien/tokens`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          //this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      async getMLUser(){
        try{                   
          // await baseApi().get(`/ctconnect/product/1`).then((response)=>{        
            // await baseApi().get(`/mlibre/user`).then((response)=>{     
              await baseApi().get(`/mlibre/user/me`).then((response)=>{        
                // await baseApi().get(`/mlibre/usertest`).then((response)=>{        
          // await baseApi().get(`/mlibre/add/article`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          //this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      async getMLAdd(){
        try{                   
          await baseApi().get(`/mlibre/add/article`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          //this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      async getCTConnect(){
        try{                   
          // await baseApi().get(`/ctconnect/product/1`).then((response)=>{        
          await baseApi().get(`/`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          //this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      }
      
    },
    data(){
       return{
        headers: [
          { text: 'Descripcion', value: 'name' },
          { text: 'sku', value: 'constructionDate' },
          { text: 'clave sat', value: 'constructionDate' },
          { text: 'precio', value: 'location' },
          { text: 'precio publico', value: 'location' },
          { text: 'stock', value: 'location' },
          { text: 'stock min', value: 'location' },
          { text: 'stock max', value: 'location' },
          { text: 'accion', value: 'location' },
      ],
      items: [
        {
          name: 'Great Pyramid of Giza',
          location: 'Egypt',
          height: '146.6',
          base: '230.4',
          volume: '2583285',
          constructionDate: 'c. 2580–2560 BC',
        },
        {
          name: 'Pyramid of Khafre',
          location: 'Egypt',
          height: '136.4',
          base: '215.3',
          volume: '1477485',
          constructionDate: 'c. 2570 BC',
        },
        {
          name: 'Red Pyramid',
          location: 'Egypt',
          height: '104',
          base: '220',
          volume: '1602895',
          constructionDate: 'c. 2590 BC',
        },
        {
          name: 'Bent Pyramid',
          location: 'Egypt',
          height: '101.1',
          base: '188.6',
          volume: '1200690',
          constructionDate: 'c. 2600 BC',
        },
        {
          name: 'Pyramid of the Sun',
          location: 'Mexico',
          height: '65',
          base: '225',
          volume: '1237097',
          constructionDate: 'c. 200 CE',
        },
      ],
       
       }
      
    },
    created(){    
    },
  }
  </script>